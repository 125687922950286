import { ElementCategoryData, ElementData } from '@wix/platform-editor-sdk';
import {
  ComponentIds as DonationFormComponentIds,
  StateIds,
} from '@/components/DonationForm/constants';
import { getRole } from '@/common/utils/getRole';
import { ILocaleKeys } from '@/locale-keys';
import { ComponentIds as FrequencyWidgetComponentIds } from '@/components/FrequencyWidget/constants';
import { ComponentIds as AmountWidgetComponentIds } from '@/components/AmountWidget/constants';
import { ComponentIds as NoteWidgetComponentIds } from '@/components/NoteWidget/constants';
import { ComponentIds as GoalWidgetComponentIds } from '@/components/GoalAmountWidget/constants';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { SPECS } from '@/common/constants/specs';
import { ElementsScope } from './openShowHidePanel';

export const getElementsPanelData = (
  t: ILocaleKeys,
  flowAPI: FlowAPI,
  state: StateIds,
  scope: ElementsScope,
): { elementsData: ElementData[]; categoriesData: ElementCategoryData[] } => {
  const isGoalEnabled = flowAPI.experiments.enabled(SPECS.CampaignGoal);
  switch (scope) {
    case ElementsScope.WIDGET:
      if (state === StateIds.Form || !isGoalEnabled) {
        return {
          elementsData: getFormStateWidgetElements(t, isGoalEnabled),
          categoriesData: [],
        };
      } else if (state === StateIds.GoalReached) {
        return {
          elementsData: getGoalReachedStateWidgetElements(t),
          categoriesData: [],
        };
      } else if (state === StateIds.GoalNotReached) {
        return {
          elementsData: getExpiredStateWidgetElements(t),
          categoriesData: [],
        };
      } else {
        return {
          elementsData: [],
          categoriesData: [],
        };
      }
    case ElementsScope.GOAL:
      return {
        elementsData: getGoalElements(t),
        categoriesData: [],
      };
    default:
      return {
        elementsData: [],
        categoriesData: [],
      };
  }
};

const getFormStateWidgetElements = (
  t: ILocaleKeys,
  isGoalEnabled: boolean,
): ElementData[] => {
  let i = 0;
  return [
    {
      label: t.donationForm.formWidget.elements.title.label(),
      identifier: { role: getRole(DonationFormComponentIds.Title) },
      index: i++,
    },
    {
      label: t.donationForm.formWidget.elements.description.label(),
      identifier: { role: getRole(DonationFormComponentIds.Description) },
      index: i++,
    },
    ...(isGoalEnabled
      ? [
          {
            label: t.donations.widget.progressBarElements.display.sectionName(),
            tooltipData: {
              content:
                t.donations.widget.progressBarElements.display.progressBarTooltip(),
            },
            identifier: { role: getRole(DonationFormComponentIds.GoalAmount) },
            index: i++,
          },
        ]
      : []),
    {
      label: t.donationForm.formWidget.elements.frequency.label(),
      identifier: { role: getRole(FrequencyWidgetComponentIds.Label) },
      index: i++,
    },
    {
      label: t.donationForm.formWidget.elements.amount.label(),
      identifier: { role: getRole(AmountWidgetComponentIds.Label) },
      index: i++,
    },
    {
      label: t.donationForm.formWidget.elements.donorNote.label(),
      identifier: { role: getRole(NoteWidgetComponentIds.Label) },
      index: i++,
    },
  ];
};

const getExpiredStateWidgetElements = (t: ILocaleKeys): ElementData[] => {
  return [
    {
      label: t.donations.widget.progressBar.title.displayName(),
      identifier: {
        role: getRole(DonationFormComponentIds.ExpiredStateTitle),
      },
      index: 0,
    },
    {
      label: t.donations.widget.progressBarElements.display.sectionName(),
      tooltipData: {
        content:
          t.donations.widget.progressBarElements.display.progressBarTooltip(),
      },
      identifier: {
        role: getRole(DonationFormComponentIds.ExpiredStateGoalWidget),
      },
      index: 1,
    },
    {
      label: t.donations.widget.progressBar.description.displayName(),
      identifier: {
        role: getRole(DonationFormComponentIds.ExpiredStateBody),
      },
      index: 2,
    },
    {
      label: t.donations.widget.progressBar.button.displayName(),
      identifier: {
        role: getRole(DonationFormComponentIds.ExpiredStateButton),
      },
      index: 3,
    },
  ];
};

const getGoalReachedStateWidgetElements = (t: ILocaleKeys): ElementData[] => {
  return [
    {
      label: t.donations.widget.progressBar.title.displayName(),
      identifier: {
        role: getRole(DonationFormComponentIds.GoalReachedTitle),
      },
      index: 0,
    },
    {
      label: t.donations.widget.progressBarElements.display.sectionName(),
      tooltipData: {
        content:
          t.donations.widget.progressBarElements.display.progressBarTooltip(),
      },
      identifier: {
        role: getRole(DonationFormComponentIds.GoalReachedGoalWidget),
      },
      index: 1,
    },
    {
      label: t.donations.widget.progressBar.description.displayName(),
      identifier: {
        role: getRole(DonationFormComponentIds.GoalReachedBody),
      },
      index: 2,
    },
    {
      label: t.donations.widget.progressBar.button.displayName(),
      identifier: {
        role: getRole(DonationFormComponentIds.GoalReachedButton),
      },
      index: 3,
    },
  ];
};

const getGoalElements = (t: ILocaleKeys): ElementData[] => {
  let i = 0;
  return [
    {
      label: t.donations.widget.progressBarElements.display.raisedAmount(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.DonatedAmountBox),
      },
      index: i++,
    },
    {
      label: t.donations.widget.progressBarElements.display.goalAmount(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.GoalAmountBox),
      },
      index: i++,
    },
    {
      label: t.donations.widget.progressBarElements.display.progressFill(),
      identifier: { role: getRole(GoalWidgetComponentIds.ProgressBarBox) },
      index: i++,
    },
    {
      label: t.donations.widget.progressBarElements.display.numDonations(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.DonationCountBox),
      },
      index: i++,
    },
    {
      label: t.donations.widget.progressBarElements.display.deadlineCountdown(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.TimeLeftBox),
      },
      index: i++,
    },
    {
      label:
        t.donations.widget.progressBarElements.display.percentageFromGoal(),
      identifier: {
        role: getRole(GoalWidgetComponentIds.PercentageBox),
      },
      index: i++,
    },
  ];
};
