import { ComponentIds } from '@/components/DonationForm/constants';
import { WidgetBuilder } from '@wix/app-manifest-builder';
import { getRole } from '../../utils/getRole';
import { AppManifestParams } from '../../manifest';
import { setAsSelectable } from '../../utils/setAsSelectable';
import { SPECS } from '@/common/constants/specs';

export const setFormNoteComponent = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys, flowAPI }: AppManifestParams,
) =>
  formWidgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.Comment),
    (componentBuilder) => {
      if (flowAPI.experiments.enabled(SPECS.OnlySpecifiedSelectable)) {
        setAsSelectable(componentBuilder, { canBeDeleted: false });
      }
    },
  );
