import { WidgetBuilder } from '@wix/app-manifest-builder';
import { getRole } from '../../utils/getRole';
import { AppManifestParams } from '@/editor-app/manifest/manifest';
import { setAsSelectable } from '../../utils/setAsSelectable';
import {
  getParentWidgetProps,
  getParentWidgetRef,
} from '@/editor-app/panels/refUtils';
import {
  CampaignPageSection as Section,
  openEditCampaignDashboard,
} from '@/editor-app/manifest/utils/openEditCampaignDashboard';
import {
  ElementsParentWidget as Widget,
  ElementsScope as Scope,
  openShowHidePanel,
} from '@/editor-app/panels/openShowHidePanel';
import { ComponentIds } from '@/components/DonationForm/constants';

export const setFormGoalComponent = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) => {
  setGoalComponent(formWidgetBuilder, params, ComponentIds.GoalAmount);
};

export const setExpiredGoalComponent = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) => {
  setGoalComponent(
    formWidgetBuilder,
    params,
    ComponentIds.ExpiredStateGoalWidget,
  );
};

export const setGoalReachedGoalComponent = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) => {
  setGoalComponent(
    formWidgetBuilder,
    params,
    ComponentIds.GoalReachedGoalWidget,
  );
};

const setGoalComponent = (
  formWidgetBuilder: WidgetBuilder,
  { editorSDK, flowAPI, localeKeys }: AppManifestParams,
  componentId: string,
) =>
  formWidgetBuilder.configureConnectedComponents(
    getRole(componentId),
    (componentBuilder) => {
      setAsSelectable(componentBuilder, { canBeDeleted: false });
      componentBuilder.set({
        displayName:
          localeKeys.donations.widget.progressBar.inFormComp.displayName(),
      });
      componentBuilder
        .gfpp()
        .set('mainAction1', {
          label: localeKeys.donations.widget.manage.manageGoal.button(),
          onClick: async (event) => {
            const { componentRef } = event.detail;
            const props = await getParentWidgetProps({
              editorSDK,
              componentRef,
            });
            const campaignId = props?.campaignId as string;
            if (campaignId) {
              openEditCampaignDashboard(editorSDK, campaignId, Section.Goal);
            }
          },
        })
        .set('add', {
          onClick: async (event) => {
            const { componentRef } = event.detail;
            const formWidgetRef = await getParentWidgetRef({
              editorSDK,
              componentRef,
            });
            void openShowHidePanel(
              editorSDK,
              formWidgetRef,
              flowAPI,
              Scope.GOAL,
              Widget.DONATION,
            );
          },
        })
        .set('mainAction2', {
          behavior: 'HIDE',
        })
        .set('layout', { behavior: 'HIDE' })
        .set('design', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' });
    },
  );
