import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { initLocaleKeys } from '@/common/utils/locale';
import { ActionIds, helpIds } from '@/common/constants/gfpp';
import { ComponentIds } from '@/components/NoteWidget/constants';
import { getRole } from '@/common/utils/getRole';
import {
  setAllComponentsAsUnselectable,
  setComponentsAsUnselectable,
} from '@/editor-app/manifest/utils/setAsUnselectable';
import noteWidget from './.component.json';
import { setInputFieldGfpp } from '@/editor-app/manifest/utils/InputField';
import { SPECS } from '@/common/constants/specs';
import { setAsSelectable } from '@/editor-app/manifest/utils/setAsSelectable';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  widgetBuilder.set({
    displayName: t.donationForm.donorNote.displayName(),
    nickname: 'noteWidget',
  });
  const isOnlySpecifiedSelectable = flowAPI.experiments.enabled(
    SPECS.OnlySpecifiedSelectable,
  );
  if (isOnlySpecifiedSelectable) {
    setAllComponentsAsUnselectable(widgetBuilder, noteWidget.id);
  } else {
    setComponentsAsUnselectable(widgetBuilder, [
      ComponentIds.Box,
      ComponentIds.RootBox,
      ComponentIds.LabelBox,
    ]);
  }
  widgetBuilder.behavior().set({ removable: false });
  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.donationForm.donorNote.gfpp.settings(),
      actionId: ActionIds.DONOR_NOTE_SETTINGS,
    })
    .set('add', {
      behavior: 'HIDE',
    })
    .set('help', {
      id: helpIds.DONOR_NOTE,
    })
    .set('animation', {
      behavior: 'HIDE',
    })
    .set('connect', {
      behavior: 'HIDE',
    })
    .set('design', {
      behavior: 'HIDE',
    });

  widgetBuilder
    .configureConnectedComponents(
      getRole(ComponentIds.Label),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.noteTitle.displayName(),
        });
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder, { canBeDeleted: true });
        }
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.TextBox),
      (innerWidgetBuilder) => {
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder);
        } else {
          innerWidgetBuilder.behavior().set({ preventHide: true });
        }
        innerWidgetBuilder.set({
          displayName: t.donationForm.donorNote.inputField.displayName(),
        });
        innerWidgetBuilder.behavior().set({ preventHide: true });
        setInputFieldGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.gfpp.learnMore(),
          helpIds.DONOR_NOTE,
        );
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CharCounter),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.noteField.characterCounter.displayName(),
        });
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder);
        } else {
          innerWidgetBuilder.behavior().set({ preventHide: true });
        }
        innerWidgetBuilder
          .behavior()
          .set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
        innerWidgetBuilder
          .gfpp()
          .set('mainAction1', {
            // TODO: add translation
            label: 'Change Design',
            onClick: ({ detail: { componentRef } }) => {
              void editorSDK.editor.openNativeComponentPanel('', 'settings', {
                componentRef,
              });
            },
          })
          .set('mainAction2', {
            behavior: 'HIDE',
          })
          .set('animation', { behavior: 'HIDE' })
          .set('design', { behavior: 'DEFAULT' });
      },
    );

  widgetBuilder.configureWidgetDesign((designBuilder) => {
    const widgetDesignTabsBuilder = designBuilder.tabs();

    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: '',
        })
        .groups()
        .set({
          roles: undefined,
        });
    });
  });
};
