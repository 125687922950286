import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { ComponentIds, StateIds } from '@/components/DonationForm/constants';
import { getRole } from '@/common/utils/getRole';

const getGoalWidgetId = (state: StateIds): string => {
  switch (state) {
    case StateIds.GoalNotReached:
      return ComponentIds.ExpiredStateGoalWidget;
    case StateIds.GoalReached:
      return ComponentIds.GoalReachedGoalWidget;
    case StateIds.Form:
    default:
      return ComponentIds.GoalAmount;
  }
};

export const getGoalWidgetRef = async (
  editorSDK: EditorSDK,
  formWidgetRef: ComponentRef,
  state: StateIds,
) => {
  const role = getRole(getGoalWidgetId(state));
  const [goalRef] = await editorSDK.components.findAllByRole('', {
    controllerRef: formWidgetRef,
    role,
  });
  return goalRef;
};
