import { EditorSDK } from '@wix/platform-editor-sdk';

export const enum CampaignPageSection {
  Goal = 'goal',
}

export const openManageCampaignsDashboard = (editorSDK: EditorSDK) => {
  void editorSDK.editor.openDashboardPanel('', {
    url: '/donations',
    closeOtherPanels: false,
  });
};
