import { ComponentIds } from '@/components/DonationForm/constants';
import { WidgetBuilder } from '@wix/app-manifest-builder';
import { getRole } from '../../utils/getRole';
import { AppManifestParams } from '../../manifest';
import { SPECS } from '@/common/constants/specs';

export const setMultiStateBoxComponent = (
  formWidgetBuilder: WidgetBuilder,
  { flowAPI }: AppManifestParams,
) => {
  formWidgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.MultiStateBox),
    (innerWidgetBuilder) => {
      if (!flowAPI.experiments.enabled(SPECS.OnlySpecifiedSelectable)) {
        innerWidgetBuilder.behavior().set({ preventHide: true });
        innerWidgetBuilder.behavior().set({
          closed: {
            selectable: false,
          },
        });
      }
    },
  );
};
