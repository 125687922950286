const structure = {
  "fc18cc76-ac0c-4817-8325-981feee6236a": {
    frequencyWidget: {
      appDefinitionId: "333b456e-dd48-4d6b-b32b-9fd48d74e163",
      widgetId: "94022db4-b7bf-4903-b35f-6221023a7701",
    },
    amountWidget1: {
      appDefinitionId: "333b456e-dd48-4d6b-b32b-9fd48d74e163",
      widgetId: "a76d1b7a-e1fa-4b37-b7c3-9329dd9bfe16",
    },
    noteWidget: {
      appDefinitionId: "333b456e-dd48-4d6b-b32b-9fd48d74e163",
      widgetId: "bdd72367-fd32-4e07-b67f-eb573352f26c",
    },
    goalAmountWidget1: {
      appDefinitionId: "333b456e-dd48-4d6b-b32b-9fd48d74e163",
      widgetId: "a729d76e-a505-4c49-84a5-986ff5b5b1dc",
    },
    goalReachedGoal: {
      appDefinitionId: "333b456e-dd48-4d6b-b32b-9fd48d74e163",
      widgetId: "a729d76e-a505-4c49-84a5-986ff5b5b1dc",
    },
    expiredGoal: {
      appDefinitionId: "333b456e-dd48-4d6b-b32b-9fd48d74e163",
      widgetId: "a729d76e-a505-4c49-84a5-986ff5b5b1dc",
    },
    box1: "ContainerTestkit",
    multiStateBox1: "MultiStateBoxTestkit",
    emptyState: "ContainerTestkit",
    text1: "WRichTextTestkit",
    text2: "WRichTextTestkit",
    box8: "ContainerTestkit",
    emptystateRepeater: "ContainerTestkit",
    presetsRepeater: "RepeaterTestkit",
    selectableContainer: "SelectableContainerTestkit",
    selectableContainerInput: "SelectableContainerInputTestkit",
    box7: "ContainerTestkit",
    emptyStateFrequncy: "WRichTextTestkit",
    box12: "ContainerTestkit",
    box10: "ContainerTestkit",
    repeater1: "RepeaterTestkit",
    selectableContainer1: "SelectableContainerTestkit",
    selectableContainerInput1: "SelectableContainerInputTestkit",
    box9: "ContainerTestkit",
    text3: "WRichTextTestkit",
    box15: "ContainerTestkit",
    box14: "ContainerTestkit",
    text4: "WRichTextTestkit",
    button1: "StylableButtonTestkit",
    formState: "ContainerTestkit",
    title: "WRichTextTestkit",
    description: "WRichTextTestkit",
    box16: "ContainerTestkit",
    donateButton: "StylableButtonTestkit",
    errorBox: "ContainerTestkit",
    errorInnerBox: "ContainerTestkit",
    errorText: "WRichTextTestkit",
    errorIcon: "VectorImageTestkit",
    goalReachedState: "ContainerTestkit",
    goalReachedTitle: "WRichTextTestkit",
    goalReachedBody: "WRichTextTestkit",
    goalReachedButtonBox: "ContainerTestkit",
    goalReachedButton: "StylableButtonTestkit",
    goalNotReachedState: "ContainerTestkit",
    expiredTitle: "WRichTextTestkit",
    expiredBody: "WRichTextTestkit",
    expiredButtonBox: "ContainerTestkit",
    expiredButton: "StylableButtonTestkit",
  },
  "a76d1b7a-e1fa-4b37-b7c3-9329dd9bfe16": {
    box1: "ContainerTestkit",
    amountTitle: "WRichTextTestkit",
    radioGroupBox: "ContainerTestkit",
    presetsRepeater: "RepeaterTestkit",
    selectableContainer: "SelectableContainerTestkit",
    selectableContainerInput: "SelectableContainerInputTestkit",
    msbItem: "MultiStateBoxTestkit",
    Default: "ContainerTestkit",
    defaultContainer: "ContainerTestkit",
    box7: "ContainerTestkit",
    impactTextDefault: "WRichTextTestkit",
    box8: "ContainerTestkit",
    itemTextDefault: "WRichTextTestkit",
    Selected: "ContainerTestkit",
    SelectedContainer: "ContainerTestkit",
    box5: "ContainerTestkit",
    impactTextSelected: "WRichTextTestkit",
    box6: "ContainerTestkit",
    itemTextSelected: "WRichTextTestkit",
    impactTextSingle: "WRichTextTestkit",
    customTipFieldBox: "ContainerTestkit",
    customTipInput: "TextInputTestkit",
    errorOuterBox: "ContainerTestkit",
    errorInnerBox: "ContainerTestkit",
    errorText: "WRichTextTestkit",
    errorIcon: "VectorImageTestkit",
  },
  "94022db4-b7bf-4903-b35f-6221023a7701": {
    box1: "ContainerTestkit",
    frequencyTitle: "WRichTextTestkit",
    radioGroupBox: "ContainerTestkit",
    presetsRepeater: "RepeaterTestkit",
    selectableContainer: "SelectableContainerTestkit",
    selectableContainerInput: "SelectableContainerInputTestkit",
    msbItem: "MultiStateBoxTestkit",
    Default: "ContainerTestkit",
    defaultContainer: "ContainerTestkit",
    itemTextDefault: "WRichTextTestkit",
    Selected: "ContainerTestkit",
    selectedContainer: "ContainerTestkit",
    itemTextSelected: "WRichTextTestkit",
  },
  "bdd72367-fd32-4e07-b67f-eb573352f26c": {
    box1: "ContainerTestkit",
    box2: "ContainerTestkit",
    textBox1: "TextAreaInputTestkit",
    text2: "WRichTextTestkit",
    noteTitle: "WRichTextTestkit",
  },
  "e48c8a7f-304f-4a93-a28f-219069e1aed4": {
    typWrapper: "ContainerTestkit",
    txtTitle: "WRichTextTestkit",
    txtLine2: "WRichTextTestkit",
    txtLine3: "WRichTextTestkit",
  },
  "a729d76e-a505-4c49-84a5-986ff5b5b1dc": {
    goalContainer: "ContainerTestkit",
    rootBox: "ContainerTestkit",
    headlineBox: "ContainerTestkit",
    donatedAmountBox: "ContainerTestkit",
    donatedAmountText: "WRichTextTestkit",
    goalAmountBox: "ContainerTestkit",
    goalAmountText: "WRichTextTestkit",
    progressBarBox: "ContainerTestkit",
    progressBar: "ProgressBarTestkit",
    footerBox: "ContainerTestkit",
    percentageBox: "ContainerTestkit",
    percentageText: "WRichTextTestkit",
    metricsOuterBox: "ContainerTestkit",
    metricsInnerBox: "ContainerTestkit",
    donationCountBox: "ContainerTestkit",
    donationCountText: "WRichTextTestkit",
    daysLeftBox: "ContainerTestkit",
    daysLeftText: "WRichTextTestkit",
  },
  "3409f789-360d-4d5b-80a3-756539cf748c": {
    goalWidget: {
      appDefinitionId: "333b456e-dd48-4d6b-b32b-9fd48d74e163",
      widgetId: "a729d76e-a505-4c49-84a5-986ff5b5b1dc",
    },
    box1: "ContainerTestkit",
    multiStateBox1: "MultiStateBoxTestkit",
    emptyState: "ContainerTestkit",
    emptyTitle: "WRichTextTestkit",
    emptySubtitle: "WRichTextTestkit",
    activeState: "ContainerTestkit",
  },
} as const;
export default structure;
