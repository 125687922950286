import { EditorSDK } from '@wix/platform-editor-sdk';

export const enum CampaignPageSection {
  Goal = 'goal',
  SuggestedAmounts = 'suggested-amounts',
}

export const openEditCampaignDashboard = (
  editorSDK: EditorSDK,
  campaignId: string,
  anchor?: CampaignPageSection,
) => {
  const relativeUrl = `/donations/campaign/${campaignId}`;
  const url = anchor ? `${relativeUrl}#${anchor}` : relativeUrl;
  void editorSDK.editor.openDashboardPanel('', {
    url,
    closeOtherPanels: false,
  });
};
