import { helpIds } from '@/common/constants/gfpp';
import { ComponentIds } from '@/components/DonationForm/constants';
import { WidgetBuilder } from '@wix/app-manifest-builder';
import { getRole } from '../../utils/getRole';
import { hideSeoA11y } from '../../utils/hideSeoA11y';
import { AppManifestParams } from '../../manifest';
import { setAsSelectable } from '../../utils/setAsSelectable';
import { SPECS } from '@/common/constants/specs';

export const setFormDescriptionComponent = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys, flowAPI }: AppManifestParams,
) =>
  formWidgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.Description),
    (componentBuilder) => {
      hideSeoA11y(componentBuilder);
      componentBuilder.set({
        displayName: localeKeys.donationForm.description.displayName(),
      });
      componentBuilder
        .gfpp()
        .set('connect', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' })
        .set('help', { id: helpIds.FORM_DESCRIPTION });
      if (flowAPI.experiments.enabled(SPECS.OnlySpecifiedSelectable)) {
        setAsSelectable(componentBuilder, { canBeDeleted: true });
      }
    },
  );

export const setExpiredDescriptionComponent = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) =>
  setEditableDescriptionComponent(
    formWidgetBuilder,
    params,
    ComponentIds.ExpiredStateBody,
  );

export const setGoalReachedDescriptionComponent = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) =>
  setEditableDescriptionComponent(
    formWidgetBuilder,
    params,
    ComponentIds.GoalReachedBody,
  );

const setEditableDescriptionComponent = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys }: AppManifestParams,
  id: string,
) =>
  formWidgetBuilder.configureConnectedComponents(
    getRole(id),
    (componentBuilder) => {
      hideSeoA11y(componentBuilder);
      setAsSelectable(componentBuilder, { canBeDeleted: true });
      componentBuilder.set({
        displayName:
          localeKeys.donations.widget.progressBar.description.displayName(),
      });
      componentBuilder
        .gfpp()
        .set('connect', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' })
        .set('help', { id: helpIds.FORM_DESCRIPTION });
    },
  );
