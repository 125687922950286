import { EditorSDK, EventType } from '@wix/platform-editor-sdk';

// TODO: Temp solution until editor-platfrom will provide a proper API https://wix.slack.com/archives/C4KPAQ33K/p1741686640327729
const closeElementsPanel = (editorSDK: EditorSDK) =>
  editorSDK.editor.closePanel(
    undefined,
    'compPanels.panels.Widget.elementsPanel',
  );

export const closeElementsPanelOnViewStateChanged = async (
  editorSDK: EditorSDK,
) => {
  editorSDK.addEventListener(
    EventType.viewStateChanged,
    () => closeElementsPanel(editorSDK),
    {
      once: true,
    },
  );
};
