import { getRole } from '@/common/utils/getRole';
import {
  ConnectedComponentsBuilder,
  WidgetBuilder,
} from '@wix/app-manifest-builder';
import widgetsStructure from '../../../../target/generated/widgets-structure';

export const setAsUnselectable = (
  componentBuilder: ConnectedComponentsBuilder,
  { canBeDeleted }: { canBeDeleted: boolean | undefined },
) => {
  componentBuilder.behavior().set({
    closed: {
      selectable: false,
      hideFromHierarchy: true,
    },
    ...(canBeDeleted !== undefined ? { preventHide: !canBeDeleted } : {}),
  });
};

export const setComponentsAsUnselectable = (
  widgetBuilder: WidgetBuilder,
  componentsIds: string[],
  { canBeDeleted }: { canBeDeleted?: boolean } = {},
) => {
  componentsIds.forEach((id) => {
    widgetBuilder.configureConnectedComponents(getRole(id), (builder) =>
      setAsUnselectable(builder, { canBeDeleted }),
    );
  });
};

export const setAllComponentsAsUnselectable = (
  widgetBuilder: WidgetBuilder,
  widgetId: string,
  { excludeIds = [] }: { excludeIds?: string[] } = {},
) => {
  const roles = Object.keys(widgetsStructure[widgetId]);
  roles.forEach((role) => {
    if (!excludeIds.includes(role)) {
      widgetBuilder.configureConnectedComponents(role, (builder) =>
        setAsUnselectable(builder, { canBeDeleted: false }),
      );
    }
  });
};
