import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { initLocaleKeys } from '@/common/utils/locale';
import { helpIds } from '@/common/constants/gfpp';
import { ComponentIds } from '@/components/AmountWidget/constants';
import {
  setAllComponentsAsUnselectable,
  setComponentsAsUnselectable,
} from '@/editor-app/manifest/utils/setAsUnselectable';
import { getRole } from '@/common/utils/getRole';
import {
  setSelectableContainerGfpp,
  setSelectableTextGfpp,
} from '@/editor-app/manifest/utils/SelectableRepeater';
import { setInputFieldGfpp } from '@/editor-app/manifest/utils/InputField';
import { SPECS } from '@/common/constants/specs';
import amoutWidget from './.component.json';
import { setAsSelectable } from '@/editor-app/manifest/utils/setAsSelectable';
import { ConnectedComponentsBuilder } from '@wix/app-manifest-builder';
import { EditorSDK } from '@wix/platform-editor-sdk';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const isOnlySpecifiedSelectable = flowAPI.experiments.enabled(
    SPECS.OnlySpecifiedSelectable,
  );
  const isAmountImpactEnabled = flowAPI.experiments.enabled(SPECS.AmountImpact);

  if (isOnlySpecifiedSelectable) {
    setAllComponentsAsUnselectable(widgetBuilder, amoutWidget.id);
  }

  widgetBuilder.set({
    displayName: t.donationForm.amount.displayName(),
    nickname: 'AmountWidgetWidget',
  });

  widgetBuilder.behavior().set({ removable: false });

  const selectableContainerComponents: Record<string, { displayName: string }> =
    {
      [ComponentIds.itemContainerDefault]: {
        displayName: t.donationForm.amountOption.regular.displayName(),
      },
      [ComponentIds.itemContainerSelected]: {
        displayName: t.donationForm.amountOption.selected.displayName(),
      },
    };

  const amountImpactOnlyTextComponents:
    | Record<string, { displayName: string; panelHeader: string }>
    | {} = isAmountImpactEnabled
    ? {
        [ComponentIds.impactTextSelected]: {
          displayName: t.donations.widget.progressBar.impact.displayName(),
          panelHeader: t.donations.widget.progressBar.impact.displayName(),
        },
        [ComponentIds.impactTextDefault]: {
          displayName: t.donations.widget.progressBar.impact.displayName(),
          panelHeader: t.donations.widget.progressBar.impact.displayName(),
        },
        [ComponentIds.impactTextSingle]: {
          displayName: t.donations.widget.progressBar.impact.displayName(),
          panelHeader: t.donations.widget.progressBar.impact.displayName(),
        },
      }
    : {};

  const designOnlyTextComponents: Record<
    string,
    { displayName: string; panelHeader: string }
  > = {
    [ComponentIds.itemTextDefault]: {
      displayName: t.donationForm.amountText.regular.displayName(),
      panelHeader: t.donationForm.amountText.regular.gfpp.design(),
    },
    [ComponentIds.itemTextSelected]: {
      displayName: t.donationForm.amountText.selected.displayName(),
      panelHeader: t.donationForm.amountText.selected.gfpp.design(),
    },
    ...amountImpactOnlyTextComponents,
  };

  widgetBuilder
    .gfpp()
    .set('add', { behavior: 'HIDE' })
    .set('help', { id: helpIds.AMOUNT })
    .set('animation', { behavior: 'HIDE' });

  Object.entries(selectableContainerComponents).forEach(
    ([componentId, { displayName }]) => {
      widgetBuilder.configureConnectedComponents(
        getRole(componentId),
        (componentBuilder) => {
          setSelectableContainerComponent(
            componentBuilder,
            displayName,
            isOnlySpecifiedSelectable,
            editorSDK,
            t.donationForm.gfpp.learnMore(),
          );
        },
      );
    },
  );

  Object.entries(designOnlyTextComponents).forEach(
    ([componentId, { displayName, panelHeader }]) => {
      widgetBuilder.configureConnectedComponents(
        getRole(componentId),
        (componentBuilder) => {
          setDesignOnlyTextComponent(
            componentBuilder,
            displayName,
            isOnlySpecifiedSelectable,
            editorSDK,
            panelHeader,
          );
        },
      );
    },
  );

  widgetBuilder
    .configureConnectedComponents(
      getRole(ComponentIds.Label),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.amountTitle.displayName(),
        });
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder, { canBeDeleted: true });
        }
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CustomAmountBox),
      (innerWidgetBuilder) => {
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CustomAmountDefaultBtn),
      (innerWidgetBuilder) => {
        innerWidgetBuilder
          .gfpp()
          .set('connect', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' })
          .set('animation', { behavior: 'HIDE' });
      },
    )
    .configureConnectedComponents(
      getRole(ComponentIds.CustomAmountFieldInput),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.set({
          displayName: t.donationForm.otherAmountField.displayName(),
        });
        setInputFieldGfpp(
          innerWidgetBuilder,
          editorSDK,
          t.donationForm.gfpp.learnMore(),
          helpIds.AMOUNT,
        );
        if (isOnlySpecifiedSelectable) {
          setAsSelectable(innerWidgetBuilder, { canBeDeleted: false });
        }
      },
    );

  if (!isOnlySpecifiedSelectable) {
    setComponentsAsUnselectable(widgetBuilder, [
      ComponentIds.Repeater,
      ComponentIds.ListBox,
      ComponentIds.multiStateItem,
      ComponentIds.RepeaterItem,
      ComponentIds.RepeaterItemInput,
      ComponentIds.CustomAmountField,
      ComponentIds.itemContainerSelectedState,
      ComponentIds.itemContainerDefaultState,
    ]);
  }
};

const setDesignOnlyTextComponent = (
  innerWidgetBuilder: ConnectedComponentsBuilder,
  displayName: string,
  isOnlySpecifiedSelectable: boolean,
  editorSDK: EditorSDK,
  panelHeader: string,
) => {
  innerWidgetBuilder.set({
    displayName,
  });
  innerWidgetBuilder
    .behavior()
    .set({ dataEditOptions: 'TEXT_STYLE_ONLY', preventHide: true });
  setSelectableTextGfpp(innerWidgetBuilder, editorSDK, panelHeader);
  if (isOnlySpecifiedSelectable) {
    setAsSelectable(innerWidgetBuilder);
  }
};

const setSelectableContainerComponent = (
  componentBuilder: ConnectedComponentsBuilder,
  displayName: string,
  isOnlySpecifiedSelectable: boolean,
  editorSDK: EditorSDK,
  learnMoreText: string,
) => {
  setAsSelectable(componentBuilder, { canBeDeleted: false });
  componentBuilder.set({
    displayName,
  });
  if (isOnlySpecifiedSelectable) {
    setAsSelectable(componentBuilder);
  } else {
    componentBuilder.behavior().set({
      closed: {
        selectable: true,
      },
      preventHide: true,
    });
  }
  componentBuilder.set({
    displayName,
  });
  setSelectableContainerGfpp(
    componentBuilder,
    editorSDK,
    learnMoreText,
    helpIds.AMOUNT,
  );
};
