import { ConnectedComponentsBuilder } from '@wix/yoshi-flow-editor';
import { EditorSDK } from '@wix/platform-editor-sdk';

export const setInputFieldGfpp = (
  componentBuilder: ConnectedComponentsBuilder,
  editorSDK: EditorSDK,
  mainActionLabel: string,
  helpId: string,
) => {
  componentBuilder
    .gfpp()
    .set('mainAction1', {
      label: mainActionLabel,
      onClick: ({ detail: { componentRef } }) => {
        void editorSDK.editor.openHelpPanel('', { helpId });
      },
    })
    .set('connect', { behavior: 'HIDE' })
    .set('settings', { behavior: 'HIDE' })
    .set('layout', { behavior: 'DEFAULT' })
    .set('animation', { behavior: 'HIDE' });
};
