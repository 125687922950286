import { WidgetBuilder } from '@wix/app-manifest-builder';
import { AppManifestParams } from '../manifest';
import {
  setExpiredTitleComponent,
  setFormTitleComponent,
  setGoalReachedTitleComponent,
} from './components/DonationForm.manifest.title';
import {
  setFormDescriptionComponent,
  setExpiredDescriptionComponent,
  setGoalReachedDescriptionComponent,
} from './components/DonationForm.manifest.description';
import {
  setExpiredButtonComponent,
  setFormButtonComponent,
  setGoalReachedButtonComponent,
} from './components/DonationForm.manifest.button';
import { setMultiStateBoxComponent } from './components/DonationForm.manifest.multiStateBox';
import { setFormNoteComponent } from './components/DonationForm.manifest.note';
import {
  ComponentIds as Ids,
  EmptyStateComponentIds,
} from '@/components/DonationForm/constants';
import {
  setAllComponentsAsUnselectable,
  setComponentsAsUnselectable,
} from '@/editor-app/manifest/utils/setAsUnselectable';
import { SPECS } from '@/common/constants/specs';
import formWidget from '@/components/DonationForm/.component.json';
import {
  setExpiredGoalComponent,
  setFormGoalComponent,
  setGoalReachedGoalComponent,
} from './components/DonationForm.manifest.goal';
import { setAmountWidgetComponent } from '@/editor-app/manifest/DonationForm/components/DonationForm.manifest.amountWidget';

export const setDonationFormComponents = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) => {
  const { flowAPI } = params;
  const isOnlySpecifiedSelectable = flowAPI.experiments.enabled(
    SPECS.OnlySpecifiedSelectable,
  );
  const isAmountImpactEnabled = flowAPI.experiments.enabled(SPECS.AmountImpact);
  if (isOnlySpecifiedSelectable) {
    setAllComponentsAsUnselectable(formWidgetBuilder, formWidget.id);
  }
  setMultiStateBoxComponent(formWidgetBuilder, params);

  setFormTitleComponent(formWidgetBuilder, params);
  setFormDescriptionComponent(formWidgetBuilder, params);
  setFormGoalComponent(formWidgetBuilder, params);
  setFormNoteComponent(formWidgetBuilder, params);
  setFormButtonComponent(formWidgetBuilder, params);

  setGoalReachedTitleComponent(formWidgetBuilder, params);
  setGoalReachedDescriptionComponent(formWidgetBuilder, params);
  setGoalReachedGoalComponent(formWidgetBuilder, params);
  setGoalReachedButtonComponent(formWidgetBuilder, params);

  setExpiredTitleComponent(formWidgetBuilder, params);
  setExpiredDescriptionComponent(formWidgetBuilder, params);
  setExpiredGoalComponent(formWidgetBuilder, params);
  setExpiredButtonComponent(formWidgetBuilder, params);

  if (isAmountImpactEnabled) {
    setAmountWidgetComponent(formWidgetBuilder, params);
  }

  if (!isOnlySpecifiedSelectable) {
    setComponentsAsUnselectable(formWidgetBuilder, [
      Ids.DonateButtonContainer,
      Ids.PremiumErrorIcon,
      Ids.PremiumError,
      Ids.PremiumErrorText,
      Ids.PremiumErrorContainer,
      Ids.MultiStateBox,
      Ids.FormStateBox,
      Ids.RootBox,
      Ids.EmptyStateBox,
      Ids.Amount,
      Ids.Frequency,
      Ids.EmptyStateBody,
      Ids.EmptyStateTitle,
    ]);
    setComponentsAsUnselectable(formWidgetBuilder, EmptyStateComponentIds);
    if (flowAPI.experiments.enabled(SPECS.CampaignGoal)) {
      setComponentsAsUnselectable(formWidgetBuilder, [
        Ids.GoalReachedButtonBox,
        Ids.ExpiredStateButtonBox,
      ]);
    }
  }
};
