import {
  AddComponentHandler,
  ComponentRef,
  EditorSDK,
  RemoveComponentHandler,
} from '@wix/platform-editor-sdk';
import { initLocaleKeys } from '@/common/utils/locale';
import { getElementsPanelData } from './getElementsPanelData';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { StateIds } from '@/components/DonationForm/constants';
import { getCollapsedRefComponentByRole, getParentIfWidget } from './refUtils';
import { addCollapsedComponent } from './addCollapsedComponent';
import { removeCollapsedComponent } from './removeCollapsedComponent';
import { closeElementsPanelOnViewStateChanged } from './updatePanelContentOnViewStateChanged';
import { getGoalWidgetRef } from './getGoalWidgetRef';

export const enum ElementsScope {
  WIDGET = 'WIDGET',
  GOAL = 'GOAL',
}

export const enum ElementsParentWidget {
  DONATION = 'DonationForm',
  GOAL = 'StandaloneGoalAmount',
}

export const openShowHidePanel = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  flowAPI: FlowAPI,
  scope: ElementsScope,
  parentWidget: ElementsParentWidget,
): Promise<void> => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const widgetState = (await editorSDK.editor.widgets.getViewState('token', {
    componentRef: widgetRef,
  })) as StateIds;

  const getScopedWidgetRef = () => {
    if (
      scope === ElementsScope.GOAL &&
      parentWidget === ElementsParentWidget.DONATION
    ) {
      return getGoalWidgetRef(editorSDK, widgetRef, widgetState);
    } else {
      return widgetRef;
    }
  };

  const addComponentHandler: AddComponentHandler = async ({ role }) => {
    const componentRef = await getCollapsedRefComponentByRole({
      editorSDK,
      widgetRef,
      role,
    });
    return addCollapsedComponent(editorSDK, flowAPI, { componentRef, role });
  };

  const removeComponentHandler: RemoveComponentHandler = async (
    componentRef,
    { role },
  ) => {
    componentRef = await getParentIfWidget({
      editorSDK,
      componentRef,
    });
    return removeCollapsedComponent(editorSDK, flowAPI, {
      componentRef,
      role,
    });
  };

  const { elementsData, categoriesData } = getElementsPanelData(
    t,
    flowAPI,
    widgetState,
    scope,
  );

  closeElementsPanelOnViewStateChanged(editorSDK);

  const scopedWidgetRef = await getScopedWidgetRef();

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef: scopedWidgetRef,
    elementsData,
    categoriesData,
    addComponentHandler,
    removeComponentHandler,
  });
};
