import { FlowAPI, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import {
  IPanelsApi,
  PanelsApiFactory,
} from '@wix/blocks-widget-services/panels';
import blockWidgetDescriptor from '../../../blocks-widget-descriptor.json';
import formWidget from '@/components/DonationForm/.component.json';
import { initLocaleKeys } from '@/common/utils/locale';
import { setDonationFormDesign } from '@/editor-app/manifest/DonationForm/DonationForm.manifest.design';
import { setDonationFormStates } from './DonationForm/DonationForm.manifest.states';
import { setDonationFormGFPPs } from './DonationForm/DonationForm.manifest.gfpp';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { ILocaleKeys } from '@/locale-keys';
import { setManagementActions } from './manifest.managementActions';
import { setDonationFormComponents } from './DonationForm/DonationForm.manifest.components';

export type AppManifestParams = {
  editorSDK: EditorSDK;
  localeKeys: ILocaleKeys;
  flowAPI: FlowAPI;
  panelsApi: IPanelsApi;
  revision: string;
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType: contextParams.origin.type,
    essentials: contextParams.essentials,
  });
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const revision = blockWidgetDescriptor[formWidget.id].revision.toString();
  const params = {
    editorSDK,
    localeKeys: t,
    flowAPI,
    panelsApi,
    revision,
  };
  const manifest = appManifestBuilder
    .configureWidget(formWidget.controllerId, (formWidgetBuilder) => {
      setDonationFormStates(formWidgetBuilder, params);
      setDonationFormGFPPs(formWidgetBuilder, params);
      setDonationFormComponents(formWidgetBuilder, params);
      setDonationFormDesign(formWidgetBuilder, params);
    })
    .configureManagementActions((managementActionsBuilder) => {
      setManagementActions(managementActionsBuilder, params);
    })
    .build();
  return {
    ...manifest,
  };
};
