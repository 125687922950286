import { SPECS } from '@/common/constants/specs';
import { StateIds } from '@/components/DonationForm/constants';
import { WidgetBuilder } from '@/editor-app/types';
import { AppManifestParams } from '../manifest';

export const setDonationFormStates = (
  formWidgetBuilder: WidgetBuilder,
  { flowAPI, localeKeys }: AppManifestParams,
): void => {
  const isGoalEnabled = flowAPI.experiments.enabled(SPECS.CampaignGoal);
  if (isGoalEnabled) {
    formWidgetBuilder
      .configureWidgetViewState(StateIds.Form, (stateBuilder) => {
        stateBuilder.set({
          initial: true,
          title:
            localeKeys.donations.widget.breadcrumbs.state.collectingDonations(),
        });
      })
      .configureWidgetViewState(StateIds.GoalNotReached, (stateBuilder) => {
        stateBuilder.set({
          title: localeKeys.donations.widget.breadcrumbs.state.deadlinePassed(),
        });
      })
      .configureWidgetViewState(StateIds.GoalReached, (stateBuilder) => {
        stateBuilder.set({
          title: localeKeys.donations.widget.breadcrumbs.state.goalReached(),
        });
      });
  }
};
