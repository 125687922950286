import { ConnectedComponentsBuilder } from '@wix/app-manifest-builder';

export const setAsSelectable = (
  componentBuilder: ConnectedComponentsBuilder,
  { canBeDeleted = false }: { canBeDeleted?: boolean } = {},
) => {
  componentBuilder.behavior().set({
    closed: {
      selectable: true,
      hideFromHierarchy: false,
    },
    preventHide: !canBeDeleted,
  });
};
