import { ConnectedComponentsBuilder } from '@wix/app-manifest-builder';

export const hideSeoA11y = (componentBuilder: ConnectedComponentsBuilder) => {
  componentBuilder.panel<'WRichText'>('settings').configureSections({
    seoA11y: {
      hidden: true,
    },
    verticalText: {
      hidden: false,
    },
  });
};
