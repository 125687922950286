import { initLocaleKeys } from '@/common/utils/locale';
import { FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ActionIds } from '@/common/constants/gfpp';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const localeKeys = initLocaleKeys(flowAPI.translations.i18n);

  widgetBuilder.set({
    displayName: localeKeys.editor.donationForm.label(),
    nickname: 'DonationFormWidget',
  });

  widgetBuilder.gfpp().set('mainAction1', {
    label: localeKeys.editor.gfpp.chooseCampaign.label(),
    actionId: ActionIds.DONATION_FORM_CHOOSE_CAMPAIGN,
  });

  widgetBuilder.gfpp().set('mainAction2', {
    label: localeKeys.editor.gfpp.manageCampaigns.label(),
    actionId: ActionIds.MANAGE_CAMPAIGN,
  });
};
