import { ComponentIds } from '@/components/DonationForm/constants';
import { WidgetBuilder } from '@/editor-app/types';
import { getRole } from '@/common/utils/getRole';
import { helpIds } from '@/common/constants/gfpp';
import { AppManifestParams } from '../manifest';

export const setDonationFormDesign = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys }: AppManifestParams,
): void => {
  formWidgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: localeKeys.donationForm.formWidget.design.panelHeader(),
      customHelpId: helpIds.DONATION_FORM_DESIGN,
    });
    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder
        .set({
          label:
            localeKeys.donationForm.formWidget.design.background.sectionName(),
        })
        .groups()
        .set({
          roles: [
            ComponentIds.FormStateBox,
            ComponentIds.GoalReachedBox,
            ComponentIds.ExpiredStateBox,
          ].map(getRole),
        });
    });
  });
};
