import { WidgetInstallationType } from '@wix/platform-editor-sdk';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import goalWidget from '@/components/StandaloneGoalAmountWidget/.component.json';

const enum Presets {
  Default = 'variants-m7kj0f6k1',
}

export const addGoalWidget = async (editorSDK: FlowEditorSDK) => {
  await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: goalWidget.id,
    installationType: 'closed' as WidgetInstallationType,
    scopedPresets: {
      mobile: {
        layout: Presets.Default,
        style: Presets.Default,
      },
      desktop: {
        layout: Presets.Default,
        style: Presets.Default,
      },
    },
    layout: {
      height: 120,
      width: 420,
      x: 240,
      y: 100,
    },
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        maxWidth: {
          type: 'percentage',
          value: 100,
        },
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        width: {
          type: 'px',
          value: 500,
        },
      },
      itemLayout: {
        alignSelf: 'center',
        margins: {
          top: {
            type: 'px',
            value: 20,
          },
          bottom: {
            type: 'px',
            value: 20,
          },
        },
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        id: '',
        justifySelf: 'center',
        type: 'GridItemLayout',
      },
    },
    overriddenData: [
      {
        itemType: 'connections',
        dataItem: {
          type: 'ConnectionList',
          items: [
            {
              type: 'WixCodeConnectionItem',
              role: 'goalMeter1',
            },
          ],
        },
        compId: 'comp-ldw685nw',
        isMobile: false,
      },
    ],
  });
};
