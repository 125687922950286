import { helpIds } from '@/common/constants/gfpp';
import { ComponentIds } from '@/components/DonationForm/constants';
import { WidgetBuilder } from '@wix/app-manifest-builder';
import { getRole } from '../../utils/getRole';
import { hideSeoA11y } from '../../utils/hideSeoA11y';
import { AppManifestParams } from '../../manifest';
import { setAsSelectable } from '../../utils/setAsSelectable';
import { SPECS } from '@/common/constants/specs';

export const setFormTitleComponent = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys, flowAPI }: AppManifestParams,
) =>
  formWidgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.Title),
    (componentBuilder) => {
      hideSeoA11y(componentBuilder);
      componentBuilder.set({
        displayName: localeKeys.donationForm.title.displayName(),
      });
      componentBuilder
        .gfpp()
        .set('connect', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' })
        .set('help', { id: helpIds.FORM_TITLE });
      if (flowAPI.experiments.enabled(SPECS.OnlySpecifiedSelectable)) {
        setAsSelectable(componentBuilder, { canBeDeleted: true });
      }
    },
  );

export const setExpiredTitleComponent = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) =>
  setEditableTitleComponent(
    formWidgetBuilder,
    params,
    ComponentIds.ExpiredStateTitle,
  );

export const setGoalReachedTitleComponent = (
  formWidgetBuilder: WidgetBuilder,
  params: AppManifestParams,
) =>
  setEditableTitleComponent(
    formWidgetBuilder,
    params,
    ComponentIds.GoalReachedTitle,
  );

const setEditableTitleComponent = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys }: AppManifestParams,
  id: string,
) =>
  formWidgetBuilder.configureConnectedComponents(
    getRole(id),
    (componentBuilder) => {
      hideSeoA11y(componentBuilder);
      setAsSelectable(componentBuilder, { canBeDeleted: true });
      componentBuilder.set({
        displayName:
          localeKeys.donations.widget.progressBar.title.displayName(),
      });
      componentBuilder
        .gfpp()
        .set('connect', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' })
        .set('help', { id: helpIds.FORM_TITLE });
    },
  );
