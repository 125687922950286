import { initLocaleKeys } from '@/common/utils/locale';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { setAllComponentsAsUnselectable } from '@/editor-app/manifest/utils/setAsUnselectable';
import standaloneGoalWidget from './.component.json';
import { ActionIds } from '@/common/constants/gfpp';

export const getWidgetManifest: GetWidgetManifestFn = async (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const localeKeys = initLocaleKeys(flowAPI.translations.i18n);
  widgetBuilder.set({
    displayName:
      localeKeys.donations.widget.progressBar.standaloneComp.displayName(),
    nickname: 'goalMeter',
  });

  setAllComponentsAsUnselectable(widgetBuilder, standaloneGoalWidget.id);

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: localeKeys.donationForm.formWidget.gfpp.chooseCampaign(),
      actionId: ActionIds.STANDALONE_GOAL_CHOOSE_CAMPAIGN,
    })
    .set('mainAction2', {
      label: localeKeys.donationForm.formWidget.gfpp.manageCampaigns(),
      actionId: ActionIds.MANAGE_CAMPAIGN,
    })
    .set('add', {
      actionId: ActionIds.STANDALONE_GOAL_ELEMENTS,
    })
    .set('layout', {
      actionId: ActionIds.STANDALONE_GOAL_LAYOUT,
    })
    .set('animation', { behavior: 'HIDE' })
    .set('stretch', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' });

  widgetBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: localeKeys.donationForm.formWidget.gfpp.chooseCampaign(),
      actionId: ActionIds.STANDALONE_GOAL_CHOOSE_CAMPAIGN,
    })
    .set('mainAction2', {
      label: localeKeys.donationForm.formWidget.gfpp.manageCampaigns(),
      actionId: ActionIds.MANAGE_CAMPAIGN,
    })
    .set('layout', {
      actionId: ActionIds.STANDALONE_GOAL_LAYOUT,
    })
    .set('animation', { behavior: 'HIDE' });
};
