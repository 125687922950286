import {
  ContextParams,
  EditorSDK,
  EditorType,
  EventType,
} from '@wix/platform-editor-sdk';
import { ActionIds } from '@/common/constants/gfpp';
import {
  ElementsParentWidget as ParentWidget,
  ElementsScope as Scope,
  openShowHidePanel,
} from '@/editor-app/panels/openShowHidePanel';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { PanelNames } from '@/common/constants/panels';
import { initLocaleKeys } from '@/common/utils/locale';
import { openBlocksPanelByRevision } from '@/editor-app/panels/openBlocksPanel';
import { clickManageCampaigns } from '@wix/bi-logger-wixdonations/v2';
import { openManageCampaignsDashboard } from '@/editor-app/manifest/utils/openManageCampaignsDashboard';

export const registerGfppEvents = async (
  editorSDK: EditorSDK,
  editorType: EditorType,
  flowAPI: FlowAPI,
  options: ContextParams,
) => {
  const panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType,
    essentials: options.essentials,
  });

  const t = initLocaleKeys(flowAPI.translations.i18n);

  const registerWidgetGfppEvents = editorSDK.addEventListener(
    EventType.widgetGfppClicked,
    (event) => {
      const { id, componentRef } = event.detail;
      switch (id) {
        case ActionIds.DONATION_FORM_ELEMENTS:
          void openShowHidePanel(
            editorSDK,
            componentRef,
            flowAPI,
            Scope.WIDGET,
            ParentWidget.DONATION,
          );
          break;
        case ActionIds.DONATION_FORM_CHOOSE_CAMPAIGN:
          void openBlocksPanelByRevision(
            panelsApi,
            PanelNames.chooseCampaign,
            componentRef,
            t.donationForm.formWidget.chooseCampaign.panelHeader(),
          );
          break;
        case ActionIds.MANAGE_CAMPAIGN:
          flowAPI.bi!.report(
            clickManageCampaigns({
              origin: 'gfpp',
            }),
          );
          void openManageCampaignsDashboard(editorSDK);
          break;
        case ActionIds.STANDALONE_GOAL_ELEMENTS:
          void openShowHidePanel(
            editorSDK,
            componentRef,
            flowAPI,
            Scope.GOAL,
            ParentWidget.GOAL,
          );
          break;
        case ActionIds.STANDALONE_GOAL_CHOOSE_CAMPAIGN:
          void openBlocksPanelByRevision(
            panelsApi,
            PanelNames.standaloneGoalChooseCampaign,
            componentRef,
            t.donationForm.formWidget.chooseCampaign.panelHeader(),
          );
          break;
        case ActionIds.STANDALONE_GOAL_LAYOUT:
          void openBlocksPanelByRevision(
            panelsApi,
            PanelNames.standaloneGoalLayout,
            componentRef as any,
            t.donations.widget.progressBar.layout.header(),
          );
          break;
        case ActionIds.FREQUENCY_SETTINGS:
          void openBlocksPanelByRevision(
            panelsApi,
            PanelNames.frequencySettings,
            componentRef,
            t.donationForm.frequency.settings.panelHeader(),
          );
          break;
        case ActionIds.AMOUNT_SETTINGS:
          void openBlocksPanelByRevision(
            panelsApi,
            PanelNames.amountSettings,
            componentRef,
            t.donationForm.amount.settings.panelHeader(),
          );
          break;
        case ActionIds.DONOR_NOTE_SETTINGS:
          void openBlocksPanelByRevision(
            panelsApi,
            PanelNames.donorNoteSettings,
            componentRef,
            t.donationForm.donorNote.settings.panelHeader(),
          );
          break;
        case ActionIds.DONATION_FORM_SETTINGS:
          void openBlocksPanelByRevision(
            panelsApi,
            PanelNames.donationFormSettings,
            componentRef,
            t.donationForm.formWidget.settings.panelHeader(),
          );
          break;
        case ActionIds.DONATION_FORM_LAYOUT:
          void openBlocksPanelByRevision(
            panelsApi,
            PanelNames.donationFormLayout,
            componentRef,
            t.donationForm.formWidget.layout.panelHeader(),
          );
          break;
      }
    },
  );

  const registerInnerWidgetGfppEvents = editorSDK.addEventListener(
    EventType.componentGfppClicked,
    (event) => {
      const { id, componentRef } = event.detail;
      switch (id) {
        case ActionIds.AMOUNT_WIDGET_LAYOUT:
          void openBlocksPanelByRevision(
            panelsApi,
            PanelNames.amountWidgetLayout,
            componentRef,
            t.donations.widget.amountsLayout.header(),
          );
          break;
      }
    },
  );

  await Promise.all([registerWidgetGfppEvents, registerInnerWidgetGfppEvents]);
};
