import { WidgetBuilder } from '@wix/app-manifest-builder';
import { AppManifestParams } from '@/editor-app/manifest/manifest';
import { getRole } from '@/common/utils/getRole';
import { ComponentIds } from '@/components/DonationForm/constants';
import { setAsSelectable } from '@/editor-app/manifest/utils/setAsSelectable';
import {
  CampaignPageSection,
  openEditCampaignDashboard,
} from '@/editor-app/manifest/utils/openEditCampaignDashboard';
import { getParentWidgetProps } from '@/editor-app/panels/refUtils';
import { ActionIds } from '@/common/constants/gfpp';

export const setAmountWidgetComponent = (
  formWidgetBuilder: WidgetBuilder,
  { editorSDK, flowAPI }: AppManifestParams,
) => {
  formWidgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.Amount),
    (componentBuilder) => {
      setAsSelectable(componentBuilder, { canBeDeleted: false });
      componentBuilder.set({
        displayName: 'Amount Widget',
      });
      componentBuilder
        .gfpp()
        .set('mainAction1', {
          label: 'Manage Amount',
          onClick: async (event) => {
            const { componentRef } = event.detail;
            const props = await getParentWidgetProps({
              editorSDK,
              componentRef,
            });
            const campaignId = props?.campaignId as string;
            if (campaignId) {
              openEditCampaignDashboard(
                editorSDK,
                campaignId,
                CampaignPageSection.SuggestedAmounts,
              );
            }
          },
        })
        .set('layout', { actionId: ActionIds.AMOUNT_WIDGET_LAYOUT });
    },
  );
};
