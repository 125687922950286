import { IPanelsApi } from '@wix/blocks-widget-services/dist/types/panels';
import { ComponentRef } from '@wix/yoshi-flow-editor';
import blockWidgetDescriptor from '../../../blocks-widget-descriptor.json';
import donationForm from '@/components/DonationForm/.component.json';

export const openBlocksPanelByRevision = (
  panelsApi: IPanelsApi,
  panelName: string,
  componentRef: ComponentRef,
  title?: string,
) =>
  panelsApi.openBlocksPanel(panelName, componentRef, {
    overrides: {
      revision: blockWidgetDescriptor[donationForm.id].revision.toString(),
      title,
    },
  });
