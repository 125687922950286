import { ActionIds, helpIds } from '@/common/constants/gfpp';
import { WidgetBuilder } from '@wix/app-manifest-builder';
import { AppManifestParams } from '../manifest';

export const setDonationFormGFPPs = (
  formWidgetBuilder: WidgetBuilder,
  { localeKeys, editorSDK, flowAPI, panelsApi, revision }: AppManifestParams,
) => {
  formWidgetBuilder
    .set({
      displayName: localeKeys.donationForm.formWidget.displayName(),
      nickname: 'DonationFormWidget',
    })
    .gfpp()
    .set('mainAction1', {
      label: localeKeys.donationForm.formWidget.gfpp.chooseCampaign(),
      actionId: ActionIds.DONATION_FORM_CHOOSE_CAMPAIGN,
    })
    .set('mainAction2', {
      label: localeKeys.donationForm.formWidget.gfpp.manageCampaigns(),
      actionId: ActionIds.MANAGE_CAMPAIGN,
    })
    .set('help', { id: helpIds.DONATION_FORM })
    .set('add', {
      actionId: ActionIds.DONATION_FORM_ELEMENTS,
    })
    .set('layout', { actionId: ActionIds.DONATION_FORM_LAYOUT })
    .set('settings', { actionId: ActionIds.DONATION_FORM_SETTINGS })
    .set('animation', { behavior: 'HIDE' })
    .set('stretch', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' });

  formWidgetBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: localeKeys.donationForm.formWidget.gfpp.chooseCampaign(),
      actionId: ActionIds.DONATION_FORM_CHOOSE_CAMPAIGN,
    })
    .set('mainAction2', {
      label: localeKeys.donationForm.formWidget.gfpp.manageCampaigns(),
      actionId: ActionIds.MANAGE_CAMPAIGN,
    })
    .set('help', { id: helpIds.DONATION_FORM })
    .set('layout', { actionId: ActionIds.DONATION_FORM_LAYOUT })
    .set('settings', { actionId: ActionIds.DONATION_FORM_SETTINGS })
    .set('animation', { behavior: 'HIDE' });
};
