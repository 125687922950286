import { ManagementActionsBuilder } from '@wix/yoshi-flow-editor';
import { PlatformPanelActionIds } from '@/common/constants/panels';
import { openManageCampaignsDashboard } from '@/editor-app/manifest/utils/openManageCampaignsDashboard';
import { AppManifestParams } from './manifest';

export const setManagementActions = (
  managementActionsBuilder: ManagementActionsBuilder,
  { editorSDK, localeKeys }: AppManifestParams,
) => {
  managementActionsBuilder.mainActions().addAction({
    title: localeKeys.myBusinessPanel.manage.manageCamapigns.button(),
    icon: 'settingsAction',
    onClick: () => openManageCampaignsDashboard(editorSDK),
  });
  managementActionsBuilder.customActions().addAction({
    title: localeKeys.myBusinessPanel.manage.addNewForm.button(),
    icon: 'addAction',
    actionId: PlatformPanelActionIds.DONATION_ADD_PANEL_DEEPLINK,
    type: 'dashboard',
  });
  managementActionsBuilder.upgradeAction().set({
    upgradeType: 'SITE_UPGRADE',
    upgradeText: localeKeys.myBusinessPanel.upgradeBannertext(),
    upgradeLinkText: localeKeys.myBusinessPanel.upgradeBannerlink(),
  });
};
