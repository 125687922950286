import {
  CollapsedExpandedComponentRef,
  EditorSDK,
} from '@wix/platform-editor-sdk';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { showHideDonationsWidgetElements } from '@wix/bi-logger-wixdonations/v2';

export const addCollapsedComponent = async (
  editorSDK: EditorSDK,
  flowAPI: FlowAPI,
  { componentRef, role }: CollapsedExpandedComponentRef,
) => {
  flowAPI.bi?.report(
    showHideDonationsWidgetElements({
      element: role,
      show: false,
    }),
  );
  await editorSDK.components.refComponents.expandReferredComponent('token', {
    componentRef,
  });
};
